import {formatDate} from '@angular/common';
import {inject, Injectable} from '@angular/core';
import {GSFirebaseUploadImageConfig} from '@gigasoftware/shared/media';
import {GigaNoteDoc, GigaNoteImageState, GigaNoteTranscriptionState, NgPatFirestoreService} from '@ngpat/firebase';
import {currentValueFrom} from '@ngpat/rxjs';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';
import {DlcInputImageConfig} from '../../../input/dlc-input-image/dlc-input-image.component';

@Injectable()
export class DlcNoteFirestoreService {
  firestore: NgPatFirestoreService = inject(NgPatFirestoreService);

  firestoreDocPath$: ReplaySubject<string> = new ReplaySubject<string>(1);
  uuid$: ReplaySubject<string> = new ReplaySubject<string>(1);

  /**
   * Su[scribe to the firestore document changes.]
   */
  firestoreDocSubscribe$: Observable<GigaNoteDoc> = combineLatest([this.firestoreDocPath$, this.uuid$]).pipe(
    switchMap(([firestorePath, uuid]: [string, string]) =>
      this.firestore.onSnapshotSubject$<GigaNoteDoc>(firestorePath, {
        id: uuid,
        title: formatDate(new Date(), 'medium', 'en-US'),
        transcribedTextId: '',
        userNoteId: '',
        transcribeState: GigaNoteTranscriptionState.PENDING,
        imageState: GigaNoteImageState.PENDING,
        imagePath: '', // image is not uploaded yet, therefore empty
        firestorePath,
        parentId: 'personal'
      })
    ),
    shareReplay(1)
  );

  private uploadConfig$: ReplaySubject<Partial<GSFirebaseUploadImageConfig>> = new ReplaySubject(1);

  /**
   * Image config with the resolved firestore path, filenameWithoutExtension and firestore document path.
   */
  resolvedImageConfig$: Observable<DlcInputImageConfig> = combineLatest([
    this.firestoreDocPath$,
    this.uuid$,
    this.uploadConfig$,
    this.firestoreDocSubscribe$
  ]).pipe(
    map(
      ([firestoreDocPath, uuid, uploadConfig, firestoreDoc]: [
        string,
        string,
        Partial<GSFirebaseUploadImageConfig>,
        GigaNoteDoc
      ]) => {
        // console.log('firestoreDocPath', firestoreDocPath);

        return <DlcInputImageConfig>{
          imagePath: firestoreDoc.imagePath, // empty if not uploaded yet
          filenameWithoutExtension: uuid,
          uploadConfig: {
            ...uploadConfig,
            firestoreDoc: {
              docProperty: 'imagePath',
              firestoreDocPath: firestoreDocPath
            }
          }
        };
      }
    )
  );
  /**
   * Set the base path for the firestore collection.
   * The path should have an odd number of segments.
   *
   * [collection]/[document]/[subcollection]
   * @param basePath
   */
  setBasePath(basePath: string): void {
    // If the base path has an odd number of segments,
    // then create a uuid for the document id.
    // else use the last segment as the document id.
    const segments = basePath.split('/');
    const lastSegment = segments[segments.length - 1];
    const isOdd = segments.length % 2 === 1;
    const uuid = isOdd ? uuidv4() : lastSegment;

    const docPath = isOdd ? `${basePath}/${uuid}` : basePath;

    this.firestoreDocPath$.next(docPath);
    this.uuid$.next(uuid);
  }

  uploadConfig(uploadConfig: Partial<GSFirebaseUploadImageConfig> | null | undefined): void {
    if (uploadConfig) {
      this.uploadConfig$.next(uploadConfig);
    }
  }

  async upsertDoc(docData: Partial<GigaNoteDoc>): Promise<void> {
    const currentDoc: GigaNoteDoc | undefined = await currentValueFrom<GigaNoteDoc>(this.firestoreDocSubscribe$);

    if (currentDoc) {
      await this.firestore.upsert<GigaNoteDoc>(currentDoc.firestorePath, {
        ...currentDoc,
        ...docData
      });
    }
  }
}
