import {inject, Injectable} from '@angular/core';
import {EC_HTTPS_CALLABLE, firestoreNoteVersionDoc} from '@gigasoftware/evolving-cognition/domain';
import {
  GigaNoteDoc,
  GigaNoteTranscribeNoteVersionUpdate,
  GigaUserNoteVersion,
  NgPatFirestoreService
} from '@ngpat/firebase';
import firebase from 'firebase/compat';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {DlcNoteFirestoreService} from './dlc-note-firestore.service';
import HttpsCallableResult = firebase.functions.HttpsCallableResult;

@Injectable()
export class DlcUserNoteService {
  firestore: NgPatFirestoreService = inject(NgPatFirestoreService);
  noteFirestore = inject(DlcNoteFirestoreService);

  onAddUserNoteTextVersion$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  firestoreUserNoteLatestVersionText$: Observable<string> = this.noteFirestore.firestoreDocSubscribe$.pipe(
    filter((doc: GigaNoteDoc) => doc !== null && doc !== undefined && doc.userNoteId.length > 0),
    map((doc: GigaNoteDoc | undefined | null) => {
      if (doc !== null && doc !== undefined) {
        return firestoreNoteVersionDoc(doc.firestorePath, doc.userNoteId);
      }

      return '';
    }),
    this.firestore.onSnapshotDoc$<GigaUserNoteVersion | null | undefined>(),
    map((userNoteVersion: GigaUserNoteVersion | null | undefined) => {
      if (userNoteVersion !== null && userNoteVersion !== undefined) {
        return userNoteVersion.text;
      }

      return '';
    }),

    // Compare Firestore value with user form value
    // If Firestore value is different from user form value, do not
    // return the Firestore value
    switchMap((firestoreValue: string) => {
      return this.onAddUserNoteTextVersion$.pipe(
        take(1),
        filter((userFormValue: string) => {
          return firestoreValue !== userFormValue;
        }),
        map(() => {
          return firestoreValue;
        })
      );
    })
    // tap((userNoteText: string) => {
    //   console.log(`userNoteText: ${userNoteText}`);
    // })
  );

  constructor() {
    this.onAddUserNoteTextVersion$
      .pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        filter((userNote: string) => userNote !== null && userNote !== undefined && userNote.length > 0),
        switchMap((userNote: string) =>
          this.noteFirestore.firestoreDocSubscribe$.pipe(
            take(1),
            map((doc: GigaNoteDoc) => {
              return <GigaNoteTranscribeNoteVersionUpdate>{
                doc,
                text: userNote
              };
            })
          )
        )
      )
      .subscribe(async (payload: GigaNoteTranscribeNoteVersionUpdate) => {
        await this.addNoteTextVersion(payload);
      });
  }

  private async addNoteTextVersion(payload: GigaNoteTranscribeNoteVersionUpdate): Promise<void> {
    const firebaseCallableFunction = this.firestore.httpsCallable(EC_HTTPS_CALLABLE.ADD_USER_NOTES_VERSSION);

    const response: HttpsCallableResult = await firebaseCallableFunction(payload);
  }
}
