import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, Input, signal, ViewEncapsulation} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {GSFirebaseUploadImageConfig} from '@gigasoftware/shared/media';
import {DlcMatTabSwipeDirective} from '../../../directives/swipe/dlc-mat-tab-swipe';
import {DlcRoundedTextIconButtonComponent} from '../../button/dlc-rounded-text-icon-button/dlc-rounded-text-icon-button.component';
import {DlcInputImageComponent} from '../../input/dlc-input-image/dlc-input-image.component';
import {DlcMatInlineEditComponent} from '../../input/dlc-mat-inline-edit/dlc-mat-inline-edit.component';
import {SyncfusionRteComponent} from '../syncfusion-rte/syncfusion-rte.component';
import {DlcNoteFirestoreService} from './services/dlc-note-firestore.service';
import {DlcNoteTitleService} from './services/dlc-note-title.service';
import {DlcTranscribeNoteService} from './services/dlc-transcribed-note.service';
import {DlcUserNoteService} from './services/dlc-user-note.service';

@Component({
  selector: 'dlc-add-note',
  standalone: true,
  imports: [
    CommonModule,
    DlcInputImageComponent,
    DlcRoundedTextIconButtonComponent,
    DlcMatInlineEditComponent,
    DlcMatTabSwipeDirective,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    SyncfusionRteComponent
  ],
  templateUrl: './dlc-add-note.component.html',
  styleUrl: './dlc-add-note.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DlcUserNoteService, DlcNoteFirestoreService, DlcNoteTitleService, DlcTranscribeNoteService],
  host: {
    class: 'dlc-add-note'
  }
})
export class DlcAddNoteComponent {
  noteFirestore = inject(DlcNoteFirestoreService);
  userNoteService = inject(DlcUserNoteService);
  titleService = inject(DlcNoteTitleService);
  transcribedNoteService = inject(DlcTranscribeNoteService);

  resolvedImageConfig = toSignal(this.noteFirestore.resolvedImageConfig$);

  @Input()
  set uploadConfig(config: Partial<GSFirebaseUploadImageConfig> | undefined | null) {
    this.noteFirestore.uploadConfig(config);
  }

  /**
   * Set the base path for the firestore collection.
   * The path should have an odd number of segments.
   *
   * [collection]/[document]/[subcollection]
   * @param basePath
   */
  @Input({required: true})
  set firestoreBasePath(basePath: string) {
    this.noteFirestore.setBasePath(basePath);
  }

  disableTranscribeButton = toSignal(this.transcribedNoteService.disableTranscribeButton$);
  transcriptionInProgress = toSignal(this.transcribedNoteService.transcriptionInProgress$);
  userNoteText = toSignal(this.userNoteService.firestoreUserNoteLatestVersionText$);
  transcribeText = toSignal(this.transcribedNoteService.firestoreTranscribedTextLatestVersionText$);
  titleFormValue = toSignal(this.titleService.firestoreDocTitle$);

  constructor() {
    // this.transcribedNoteService.firestoreTranscribedTextLatestVersionText$.subscribe((text: string) => {
    //   this.transcribeText.set(text);
    // });
  }
}
