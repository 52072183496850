import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
  output,
  OutputEmitterRef,
  ViewEncapsulation
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {GigaNoteDoc} from '@ngpat/firebase';
import {getNotesByParentId} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {DlcNoteListItemComponent} from './note-list-item/dlc-note-list-item.component';

@Component({
  selector: 'dlc-note-list',
  standalone: true,
  imports: [CommonModule, DlcNoteListItemComponent],
  templateUrl: './dlc-note-list.component.html',
  styleUrl: './dlc-note-list.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'dlc-note-list'
  }
})
export class DlcNoteListComponent implements OnDestroy {
  private store = inject(Store);

  parentId$: Subject<string> = new Subject<string>();
  private _onDestroy$: Subject<boolean> = new Subject();

  @Input() set parentId(value: string) {
    this.parentId$.next(value);
  }

  notes = toSignal(
    this.parentId$.pipe(
      switchMap((parentId: string) => this.store.select(getNotesByParentId(parentId)).pipe(takeUntil(this._onDestroy$)))
    )
  );

  doCopyNote: OutputEmitterRef<GigaNoteDoc> = output();
  doEditNote: OutputEmitterRef<GigaNoteDoc> = output();

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }
}
