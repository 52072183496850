import {CommonModule, NgStyle} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  input,
  OnDestroy,
  output,
  signal,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';

// import {AsyncSettingsModel, UploaderComponent, UploaderModule} from '@syncfusion/ej2-angular-inputs';
import {MentionComponent, MentionModule} from '@syncfusion/ej2-angular-dropdowns';
import {UploaderModule} from '@syncfusion/ej2-angular-inputs';
// @syuncfusion imports
// see https://ej2.syncfusion.com/angular/demos/#/material3-dark/rich-text-editor/tools
import {
  ActionBeginEventArgs,
  AudioService,
  CountService,
  EmojiPickerService,
  FileManagerService,
  FormatPainterService,
  HtmlEditorService,
  ImageService,
  LinkService,
  PasteCleanupService,
  QuickToolbarService,
  QuickToolbarSettingsModel,
  RichTextEditorComponent,
  RichTextEditorModule,
  SlashMenuSettingsModel,
  TableService,
  ToolbarService,
  ToolbarSettingsModel,
  VideoService
} from '@syncfusion/ej2-angular-richtexteditor';
import {addClass, Browser, createElement, removeClass} from '@syncfusion/ej2-base';
import * as CodeMirror from 'codemirror';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

interface DlcRteFormValue {
  text: string;
}

@Component({
  selector: 'dlc-syncfusion-rte',
  standalone: true,
  imports: [
    CommonModule,
    RichTextEditorModule,
    UploaderModule,
    MentionModule,
    NgStyle,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './syncfusion-rte.component.html',
  styleUrl: './syncfusion-rte.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
    TableService,
    FileManagerService,
    EmojiPickerService,
    VideoService,
    AudioService,
    FormatPainterService,
    QuickToolbarService,
    PasteCleanupService,
    CountService
  ],
  host: {
    class: 'dlc-syncfusion-rte'
  }
})
export class SyncfusionRteComponent implements OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();

  @ViewChild('editorMention')
  public mention!: MentionComponent;

  public fields: {
    text: string;
  } = {text: 'name'};

  private hostUrl = 'https://services.syncfusion.com/angular/production/';

  public tools: ToolbarSettingsModel = {
    items: [
      'Undo',
      'Redo',
      '|',
      // 'ImportWord',
      // 'ExportWord',
      // 'ExportPdf',
      // '|',
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'InlineCode',
      'SuperScript',
      'SubScript',
      '|',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      '|',
      'LowerCase',
      'UpperCase',
      '|',
      'Formats',
      'Alignments',
      'Blockquote',
      '|',
      'NumberFormatList',
      'BulletFormatList',
      '|',
      'Outdent',
      'Indent',
      '|',
      'CreateLink',
      'CreateTable',
      '|',
      'FormatPainter',
      'ClearFormat',
      '|',
      'EmojiPicker',
      '|',
      'SourceCode',
      'FullScreen'
    ]
  };

  // public insertImageSettings: ImageSettingsModel = {
  //   saveUrl: this.hostUrl + 'api/RichTextEditor/SaveFile',
  //   removeUrl: this.hostUrl + 'api/RichTextEditor/DeleteFile',
  //   path: this.hostUrl + 'RichTextEditor/'
  // };

  // public fileManagerSettings: FileManagerSettingsModel = {
  //   enable: true,
  //   path: '/Pictures/Food',
  //   ajaxSettings: {
  //     url: 'https://ej2-aspcore-service.azurewebsites.net/api/FileManager/FileOperations',
  //     getImageUrl: 'https://ej2-aspcore-service.azurewebsites.net/api/FileManager/GetImage',
  //     uploadUrl: 'https://ej2-aspcore-service.azurewebsites.net/api/FileManager/Upload',
  //     downloadUrl: 'https://ej2-aspcore-service.azurewebsites.net/api/FileManager/Download'
  //   }
  // };

  public quickToolbarSettings: QuickToolbarSettingsModel = {
    table: [
      'TableHeader',
      'TableRows',
      'TableColumns',
      'TableCell',
      '-',
      'BackgroundColor',
      'TableRemove',
      'TableCellVerticalAlign',
      'Styles'
    ],
    showOnRightClick: true
  };

  public placeholder = 'Type a note...';
  public slashMenuSettings: SlashMenuSettingsModel = {
    enable: true,
    items: [
      'Paragraph',
      'Heading 1',
      'Heading 2',
      'Heading 3',
      'Heading 4',
      'OrderedList',
      'UnorderedList',
      'CodeBlock',
      'Blockquote',
      'Link',
      'Image',
      'Video',
      'Audio',
      'Table',
      'Emojipicker'
    ]
  };
  // public importWord: ImportWordModel = {
  //   serviceUrl: this.hostUrl + 'api/RichTextEditor/ImportFromWord'
  // };
  // public exportWord: ExportWordModel = {
  //   serviceUrl: this.hostUrl + 'api/RichTextEditor/ExportToDocx',
  //   fileName: 'RichTextEditor.docx',
  //   stylesheet: `
  //       .e-rte-content {
  //           font-size: 1em;
  //           font-weight: 400;
  //           margin: 0;
  //       }
  //   `
  // };
  // public exportPdf: ExportPdfModel = {
  //   serviceUrl: this.hostUrl + 'api/RichTextEditor/ExportToPdf',
  //   fileName: 'RichTextEditor.pdf',
  //   stylesheet: `
  //       .e-rte-content{
  //           font-size: 1em;
  //           font-weight: 400;
  //           margin: 0;
  //       }
  //   `
  // };
  public codeMirror: any;

  rteForm: FormGroup = new FormGroup({
    text: new FormControl(null, Validators.required)
  });

  get rteFormTextValue(): string {
    return (<FormControl>this.rteForm.get('text'))?.value;
  }

  @ViewChild('fromRTE', {static: true})
  private rteObj!: RichTextEditorComponent;

  @ViewChild('valueTemplate', {static: true})
  public valueTemplate!: TemplateRef<any>;

  change = output<string>();

  valueText = signal<string>('');
  @Input()
  set value(val: string | undefined) {
    console.log(val);
    if (val && this.rteFormTextValue !== val) {
      this.valueText.set(val);
    }
  }
  value$ = toObservable(this.valueText);
  title = input('');
  titleHasValue = computed(() => {
    const title = this.title();
    return title && title.length > 0;
  });

  constructor(private fb: FormBuilder) {
    // <--- inject FormBuilder

    this.rteForm.valueChanges.pipe(takeUntilDestroyed()).subscribe((value: DlcRteFormValue) => {
      this.change.emit(value.text);
    });
  }

  createdHandler(): void {
    this.value$.pipe(takeUntil(this._onDestroy$)).subscribe((value: string | undefined) => {
      this.rteObj.value = value || '';
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }

  public mirrorConversion(e?: any): void {
    const id: string = this.rteObj.getID() + 'mirror-view';
    let mirrorView: HTMLElement = this.rteObj.element.querySelector('#' + id) as HTMLElement;
    if (e.targetItem === 'Preview') {
      this.rteObj.value = this.codeMirror.getValue();
      this.rteObj.dataBind();
      this.rteObj.rootContainer.classList.remove('e-rte-code-mirror-enabled');
      this.rteObj.focusIn();
    } else {
      this.rteObj.rootContainer.classList.add('e-rte-code-mirror-enabled');
      this.rteObj.rootContainer.classList.remove('e-source-code-enabled');
      if (!mirrorView) {
        mirrorView = createElement('div', {className: 'rte-code-mirror', id: id, styles: 'display: none;'});
        this.rteObj.rootContainer.appendChild(mirrorView);
        this.renderCodeMirror(mirrorView, this.rteObj.value === null ? '' : this.rteObj.value);
      } else {
        this.codeMirror.setValue(this.rteObj.value);
      }
      this.codeMirror.focus();
    }
  }

  public renderCodeMirror(mirrorView: HTMLElement, content: string): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.codeMirror = CodeMirror(mirrorView, {
      value: content,
      lineNumbers: true,
      mode: 'text/html',
      lineWrapping: true
    });
  }
  public handleFullScreen(e: any): void {
    const sbCntEle: HTMLElement = document.querySelector('.sb-content.e-view')!;
    const sbHdrEle: HTMLElement = document.querySelector('.sb-header.e-view')!;
    const leftBar: HTMLElement = document.querySelector('#left-sidebar')!;
    if (e.targetItem === 'Maximize') {
      if (Browser.isDevice && Browser.isIos) {
        addClass([sbCntEle, sbHdrEle], ['hide-header']);
      }
      addClass([leftBar], ['e-close']);
      removeClass([leftBar], ['e-open']);
    } else if (e.targetItem === 'Minimize') {
      if (Browser.isDevice && Browser.isIos) {
        removeClass([sbCntEle, sbHdrEle], ['hide-header']);
      }
      removeClass([leftBar], ['e-close']);
      if (!Browser.isDevice) {
        addClass([leftBar], ['e-open']);
      }
    }
  }
  public actionCompleteHandler(e: any): void {
    if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
      this.mirrorConversion(e);
    }
  }

  public actionBeginHandler(e: ActionBeginEventArgs): void {
    // if (e.requestType === 'EnterAction' && this.mention.element.classList.contains('e-popup-open')) {
    //   e.cancel = true;
    // }
    if (e.requestType === 'Maximize' || e.requestType === 'Minimize') {
      this.handleFullScreen(e);
    }
  }
}
