import {inject} from '@angular/core';
import {GigaNoteDoc, NgPatFirestoreService} from '@ngpat/firebase';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, filter} from 'rxjs/operators';
import {DlcNoteFirestoreService} from './dlc-note-firestore.service';

export class DlcNoteTitleService {
  firestore: NgPatFirestoreService = inject(NgPatFirestoreService);
  noteFirestore = inject(DlcNoteFirestoreService);

  _userUpdatedTitle = '';

  get userUpdatedTitle(): string {
    return this._userUpdatedTitle;
  }

  set userUpdatedTitle(val: string) {
    this._userUpdatedTitle = val;
  }

  firestoreDocTitle$: Observable<string> = this.noteFirestore.firestoreDocSubscribe$.pipe(
    map((doc: GigaNoteDoc) => doc.title),
    filter((title: string) => {
      return title !== this.userUpdatedTitle;
    }),
    distinctUntilChanged()
  );

  async onUpdateTitle(val: string) {
    this.userUpdatedTitle = val;
    await this.noteFirestore.upsertDoc({title: val});
  }
}
