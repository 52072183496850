<div class="flex flex-row justify-center items-center">
  <dlc-input-image
    [config]="resolvedImageConfig()"
    (deleteImage)="transcribedNoteService.onDeleteImage()"
    (uploadStart)="transcribedNoteService.onImageUploadStart()"
    (uploadComplete)="transcribedNoteService.onUploadComplete()"></dlc-input-image>
</div>
<div class="mt-2">
  <button
    dlc-rounded-text-icon-button
    [disabled]="disableTranscribeButton()"
    (click)="transcribedNoteService.doTranscribeImage()"
    color="primary">
    <mat-icon>summarize</mat-icon>
    @if (transcriptionInProgress()) {
      Transcribing...
    } @else {
      Transcribe
    }
  </button>
</div>

<div class="mt-4">
  <dlc-mat-inline-edit
    class="w-full"
    label="Note Title"
    (valueChange)="titleService.onUpdateTitle($event)"
    [value]="titleFormValue()"></dlc-mat-inline-edit>
</div>
<mat-tab-group
  mat-align-tabs="left"
  class="w-full">
  <mat-tab label="My Notes">
    <dlc-syncfusion-rte
      [value]="userNoteText()"
      (change)="userNoteService.onAddUserNoteTextVersion$.next($event)"></dlc-syncfusion-rte>
  </mat-tab>

  <mat-tab label="Transcribed Image">
    <dlc-syncfusion-rte
      [value]="transcribeText()"
      (change)="transcribedNoteService.onAddTranscribedVersion$.next($event)"></dlc-syncfusion-rte>
  </mat-tab>
</mat-tab-group>
