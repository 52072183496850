import {Injectable} from '@angular/core';
import {firestoreUserNoteCollection} from '@gigasoftware/evolving-cognition/domain';
import {GigaNoteDoc, NgPatAggregateFirebaseSnapshotChanges, NgPatFirestoreService} from '@ngpat/firebase';
import {deleteNotes, selectNgPatLoggedInUID} from '@ngpat/store';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {Action, createAction, Store} from '@ngrx/store';
import {filter, switchMap, map, tap, concatMap} from 'rxjs/operators';
import {upsertNotes} from '@ngpat/store';

const onNoteInit = createAction('[Note/API] On Note Init');

@Injectable()
export class NoteEffects implements OnInitEffects {
  getUserNotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onNoteInit),
      switchMap(() => {
        return this.store.select(selectNgPatLoggedInUID).pipe(
          filter((uid: string | null) => uid !== null && uid !== undefined && uid.length > 0),
          map((uid: string | null) => firestoreUserNoteCollection(<string>uid)),
          this.firestore.onSnapshotCollection$<GigaNoteDoc>(),
          concatMap((aggregate: NgPatAggregateFirebaseSnapshotChanges<GigaNoteDoc>) => {
            const actions = [];
            if (aggregate.added.length) {
              actions.push(upsertNotes({notes: [...aggregate.added]}));
            }

            if (aggregate.modified.length) {
              actions.push(upsertNotes({notes: [...aggregate.modified]}));
            }

            if (aggregate.removed.length) {
              actions.push(deleteNotes({ids: [...aggregate.removed]}));
            }

            return actions;
          })
        );
      })
    );
  });

  constructor(
    private firestore: NgPatFirestoreService,
    private store: Store,
    private actions$: Actions
  ) {}

  ngrxOnInitEffects(): Action {
    return onNoteInit();
  }
}
